import { getConfigValue } from '../Utils';
import ApiClient from './api-client';

class FilesService extends ApiClient {
  constructor() {
    super(getConfigValue('REACT_APP_FILE_API_URL'));
  }

  uploadScript(request: FormData): Promise<void> {
    return this.post('/scripts', request);
  }
}

const service = new FilesService();
export default service;
